import Layout from "./common/layout"
import About from "./company/About/About"
import Head from "./common/Head"
import { useFormatter } from "helpers/i18n"

const CompanyAbout = () => {
  const { currentLocale } = useFormatter()

  return (
    <>
      <Head
        showCaptcha={true}
        title={
          currentLocale === "en"
            ? "The leading e-commerce enabler in Southeast Asia"
            : "Nhà cung cấp các dịch vụ thương mại điện tử lớn nhất Việt Nam"
        }
        description={
          currentLocale === "en"
            ? "OnPoint is the leading e-commerce enabler in Southeast Asia. Our mission is to develop a comprehensive ecosystem and help businesses thrive online."
            : "OnPoint là nhà cung cấp các dịch vụ thương mại điện tử lớn nhất Việt Nam, tạo hệ sinh thái toàn diện, giúp các doanh nghiệp kinh doanh trực tuyến hiệu quả."
        }
        image={
          "https://s3.ap-southeast-1.amazonaws.com/public.onpoint.vn/company-1-meta.png"
        }
      />
      <Layout>
        <About />
      </Layout>
    </>
  )
}

export default CompanyAbout
